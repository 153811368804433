/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Row, Col, Modal,
} from 'react-bootstrap';
import './AboutUsOurBuilders.scss';
import CloseIcon from '../../../images/close.inline.svg';

const SECTION_ID = 'our-builders';

const BuilderCard = ({
  builder,
  flat,
  clickHandler,
  interactions,
  className,
}) => {
  if (!builder) {
    return <></>;
  }
  const [hovered, setHovered] = useState(false);
  const primaryPicture = getImage(builder.profilePicture.asset);
  const secondaryPicture = getImage(builder.secondaryPicture && builder.secondaryPicture.asset);
  const pictureSwitchingEnabled = secondaryPicture && interactions;

  return (
    <div
      key={builder.name}
      className={cn(
        'builder-card d-flex justfify-content align-items-center flex-column',
        { 'mb-0': flat, [className]: !!className },
      )}
    >
      <button
        type="button"
        className={cn('p-0 mb-3 builder-card__image-container', {
          'builder-card__image-container--interactions': interactions,
        })}
        onClick={clickHandler}
        onMouseOver={() => !!pictureSwitchingEnabled && setHovered(true)}
        onMouseOut={() => !!pictureSwitchingEnabled && setHovered(false)}
      >
        <GatsbyImage
          key={`${builder.name}img`}
          image={hovered ? secondaryPicture : primaryPicture}
          title={builder.name}
          alt={builder.name}
          className="builder-card__image"
        />
      </button>
      <h3 className="builder-card__title text-center fw-bold">
        {builder.name}
      </h3>
      <h3 className="builder-card__subtitle text-center fw-bold">
        {builder.position}
      </h3>
    </div>
  );
};
BuilderCard.propTypes = {
  builder: PropTypes.object,
  flat: PropTypes.bool,
  clickHandler: PropTypes.func,
  interactions: PropTypes.bool,
  className: PropTypes.string,
};
BuilderCard.defaultProps = {
  builder: null,
  flat: false,
  clickHandler: () => {},
  interactions: true,
  className: '',
};

const BuilderModal = ({ builder, show, onClose }) => {
  const createInterestBadges = (interests = [], owner) => interests.map(({ name, icon }) => (
    <span
      key={`${owner}${name}`}
      className="d-inline-flex flex-column justfify-content align-items-center me-5"
    >
      <GatsbyImage
        image={getImage(icon.asset)}
        title={name}
        alt={name}
        className="interest-icon mb-2"
      />
      <p className="text-center m-0">{name}</p>
    </span>
  ));

  return (
    builder && (
      <Modal
        show={show}
        dialogClassName="builder-modal"
        contentClassName="builder-modal__content position-relative d-flex"
        onHide={onClose}
      >
        <BuilderCard
          className="builder-modal__card"
          builder={builder}
          flat
          interactions={false}
        />
        <div className="builder-modal__info d-flex flex-column justify-content-between">
          <p className="builder-modal__info__bio">{builder.bio}</p>
          {builder.interests && builder.interests.length > 0 && (
            <div className="builder-modal__info__interests">
              <h5 className="fw-bold mb-3">Interests</h5>
              <div className="builder-modal__info__interests__list">
                {createInterestBadges(builder.interests, builder.name)}
              </div>
            </div>
          )}
        </div>
        <button
          type="button"
          onClick={onClose}
          className="builder-modal__close"
        >
          <CloseIcon className="builder-modal__close__icon" />
        </button>
      </Modal>
    )
  );
};

BuilderModal.propTypes = {
  builder: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};
BuilderModal.defaultProps = {
  builder: null,
  show: false,
  onClose: () => {},
};

const AboutUsOurBuilders = ({ currentSection }) => {
  const [selected, setSelected] = useState(null);
  const ourExpertsRef = useRef(null);
  const useMountEffect = (fun) => useEffect(() => {
    if (currentSection === SECTION_ID) {
      fun();
    }
  }, [currentSection]);
  const executeScroll = () => ourExpertsRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  const dataBuilders = useStaticQuery(
    graphql`
      query {
        allSanityBuilders(
          sort: { fields: startDate, order: ASC }
          filter: { enabled: { eq: true } }
        ) {
          edges {
            node {
              id
              name
              position
              bio
              startDate
              interests {
                name
                icon {
                  asset {
                    ...sanityFullWidthImg
                  }
                }
              }
              profilePicture {
                asset {
                  ...sanityFullWidthImg
                }
              }
              secondaryPicture {
                asset {
                  ...sanityFullWidthImg
                }
              }
            }
          }
        }
      }
    `,
  );

  const buildersList = (dataBuilders
      && dataBuilders.allSanityBuilders
      && dataBuilders.allSanityBuilders.edges)
    || [];

  return (
    <div></div>
    // <Container className="our-builders" fluid>
    //   <h3
    //     id={SECTION_ID}
    //     ref={ourExpertsRef}
    //     className="our-builders__title section-title text-white"
    //   >
    //     Our Builders
    //   </h3>
    //   <div className="our-builders__list">
    //     <Row sm={1} md={2} lg={3} xl={4}>
    //       {buildersList.map(({ node: builder }) => (
    //         <Col key={builder.name}>
    //           <BuilderCard
    //             builder={builder}
    //             interactions={!!builder.bio}
    //             clickHandler={() => setSelected(builder)}
    //           />
    //         </Col>
    //       ))}
    //     </Row>
    //   </div>
    //   <BuilderModal
    //     builder={selected}
    //     onClose={() => setSelected(null)}
    //     show={!!(selected && selected.bio)}
    //   />
    // </Container>
  );
};

AboutUsOurBuilders.propTypes = {
  currentSection: PropTypes.string,
};
AboutUsOurBuilders.defaultProps = {
  currentSection: '',
};
export default AboutUsOurBuilders;
