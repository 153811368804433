import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import './AboutUsOurStory.scss';

const SECTION_ID = 'our-story';

const AboutUsOurStory = ({ currentSection }) => {
  const ourStoryRef = useRef(null);
  const useMountEffect = (fun) => useEffect(() => {
    if (currentSection && currentSection === SECTION_ID) {
      fun();
    }
  }, [currentSection]);

  const executeScroll = () => ourStoryRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  return (
    <Container
      id={SECTION_ID}
      ref={ourStoryRef}
      className="our-story"
      fluid
    >
      <div className="our-story__content">
        <h2 className="our-story__content__title fw-bold">Our Story</h2>
        <p className="our-story__content__subtitle">
          Born out of the challenges associated with outsourced development
          groups, our team came to the realization that there is a better way
          to develop software. Having had challenges multiple times with
          outsourced groups that focused solely on their bottom line rather
          than on deliverables and client needs, we believe that providing
          software and infrastructure development services and an experience
          that rivals in-house development is critical to the success of both
          our clients and ourselves.
        </p>
      </div>
    </Container>
  );
};
AboutUsOurStory.propTypes = {
  currentSection: PropTypes.string,
};
AboutUsOurStory.defaultProps = {
  currentSection: '',
};
export default AboutUsOurStory;
