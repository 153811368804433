import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './AboutUsHero.scss';

const AboutUsHero = () => {
  const data = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "about-us-hero.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container className="about-us-hero-container position-relative d-flex flex-column justify-content-center align-items-center p-0" fluid>
      <GatsbyImage
        image={getImage(data.file.childImageSharp)}
        title="About Us Hero"
        alt="About Us Hero image"
      />

      <div className="story-text-container position-absolute d-flex flex-column align-items-start justify-content-center">
        <div className="hero-text d-flex flex-column">
          <p className="title m-0 fw-bold">About us</p>
          <p className="story m-0 text-start">
            We care about the people we serve and the people we work with,
            we all Build together!
          </p>
        </div>
      </div>
    </Container>
  );
};

export default AboutUsHero;
