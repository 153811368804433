import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  AboutUsHero, AboutUsLocationsSlider, AboutUsOurBuilders, AboutUsOurOffices, AboutUsOurStory
} from '../components/AboutUs';
import { GetInTouch } from '../components/Common';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Index = ({ location }) => {
  const [currentSection, setCurrentSection] = useState(undefined);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('section')) {
      const section = searchParams.get('section');
      setCurrentSection(section);
    }
  });

  return (
    <>
      <SEO title="About us" description="Meet Build" location={location} />
      <Layout location={location}>
        <AboutUsHero />
        <AboutUsOurStory currentSection={currentSection} />
        <AboutUsLocationsSlider />
        <AboutUsOurOffices currentSection={currentSection} />
        <AboutUsOurBuilders currentSection={currentSection} />
        <GetInTouch />
      </Layout>
    </>
  );
};

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
