import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import './AboutUsOurOffices.scss';
import PropTypes from 'prop-types';
import Sustainability from './Sustainability';

import crMap from '../../../images/cr-map-light.svg';
import bzMap from '../../../images/bz-map-light.svg';

const AboutUsOurOffices = ({ currentSection }) => {
  const ourOfficesRef = useRef(null);
  const useMountEffect = (fun) => useEffect(() => {
    if (currentSection && currentSection === 'our-offices') {
      fun();
    }
  }, [currentSection]);

  const executeScroll = () => ourOfficesRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  const data = useStaticQuery(
    graphql`{
  bg: file(relativePath: {eq: "about-us-offices-bg.png"}) {
    ...fullWidthImgHD
  }
  bzOffice: file(relativePath: {eq: "bozeman-office.png"}) {
    ...fullWidthImgHD
  }
  crOffice: file(relativePath: {eq: "cr-office.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container className="about-us-our-offices-container d-flex flex-column position-relative justify-content-center align-items-center p-0" fluid>
      <div className="background-container d-flex position-absolute">
        <GatsbyImage
          image={data.bg.childImageSharp.gatsbyImageData}
          title="About Us Offices Background"
          alt="About Us Offices Background"
        />
      </div>
      <div className="content-container d-flex flex-column position-relative pb-5">
        <div id="our-offices" ref={ourOfficesRef} className="title-text d-flex fw-bold my-5">
          <p className="fw-bold m-0">Our Offices</p>
        </div>
        <div className="d-flex flex-column flex-md-row office-cards-container px-md-5">
          <div className="office-card d-flex flex-column justify-content-center mx-md-5">
            <GatsbyImage
              image={data.bzOffice.childImageSharp.gatsbyImageData}
              title="Bozeman Office"
              alt="Bozeman Office"
            />
            <div className="d-flex map-location bz d-flex mt-1 mt-md-0">
              <div className="text-location d-flex flex-column">
                <h5 className="city-name fw-bold mb-0 ms-2 ms-md-4">Bozeman</h5>
                <p className="state-name fw-bold mt-3 mt-md-4 mb-0 ms-2 ms-md-4">Montana, USA</p>
              </div>
              <div className="d-flex map-container">
                <img className="ms-2" src={bzMap} alt="Bozeman Map" />
              </div>
            </div>
          </div>
          <div className="office-card d-flex flex-column justify-content-center mt-4 mt-md-0 mx-md-5">
            <GatsbyImage
              image={data.crOffice.childImageSharp.gatsbyImageData}
              title="CR Office"
              alt="CR Office"
            />
            <div className="d-flex map-location cr d-flex mt-1 mt-md-0">
              <div className="text-location d-flex flex-column">
                <h5 className="city-name fw-bold mb-0 ms-4">Escazu</h5>
                <p className="state-name fw-bold mt-3 mt-md-4 mb-0 ms-4">San José, Costa Rica</p>
              </div>
              <div className="d-flex map-container">
                <img src={crMap} alt="Costa Rica Map" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sustainability />

    </Container>
  );
};

AboutUsOurOffices.propTypes = {
  currentSection: PropTypes.string,
};
AboutUsOurOffices.defaultProps = {
  currentSection: '',
};

export default AboutUsOurOffices;
