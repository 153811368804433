import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import './AboutUsLocationsSlider.scss';
import ReactCompareImage from 'react-compare-image';
import montanaImage from '../../../images/montana-picture.png';
import crImage from '../../../images/cr-picture.png';
import handleSlider from '../../../images/button-slider.png';

const AboutUsLocationsSlider = () => {
  const [position, setPosition] = useState((0.5 * 100) + 20);
  const [fontMontana, setFontMontana] = useState(position);
  const [fontCR, setFontCR] = useState(position);
  const [positionYMT, setPositionYMT] = useState(0);
  const [positionXMT, setPositionXMT] = useState(7);
  const [brightnessMT, setBrightnessMT] = useState(70);
  const [positionYCR, setPositionYCR] = useState(0);
  const [positionXCR, setPositionXCR] = useState(7);
  const [brightnessCR, setBrightnessCR] = useState(70);

  const setPositionSlider = (sliderPosition) => {
    const percentagePosition = (sliderPosition * 100) + 20;
    setPosition(percentagePosition);
    setFontMontana(percentagePosition);
    if (percentagePosition < fontCR) {
      setFontCR(fontCR + (fontCR - percentagePosition));
    } else {
      setFontCR(fontCR - (percentagePosition - fontCR));
    }

    const yPositionCR = (percentagePosition - 70) * 2;
    if (yPositionCR >= 0) {
      setPositionYCR(yPositionCR);
    }

    const yPositionMT = (70 - percentagePosition) * 2;
    if (yPositionMT >= 0) {
      setPositionYMT(yPositionMT);
    }

    let xPositionMT = (70 - percentagePosition) * 2;
    if (xPositionMT <= 83) {
      if (xPositionMT <= 80) {
        xPositionMT += 7;
      }
      setPositionXMT(xPositionMT);
    } else {
      setFontMontana(0);
    }

    let xPositionCR = (percentagePosition - 70) * 2;
    if (xPositionCR <= 82) {
      if (xPositionCR <= 80) {
        xPositionCR += 7;
      }
      setPositionXCR(xPositionCR);
    } else {
      setFontCR(0);
    }

    const brightMT = brightnessMT + (percentagePosition - brightnessMT);
    if (brightMT < 120 && brightMT > 5) {
      setBrightnessMT(brightMT);
    }

    const brightCR = brightnessCR + (brightnessCR - percentagePosition);
    if (brightCR < 120 && brightCR > 5) {
      setBrightnessCR(brightCR);
    }
  };

  return (
    <Container className="about-us-slider-container d-flex flex-column position-relative p-0" fluid>
      <div className="text-locations-small-screen">
        <div className="d-flex justify-content-around">
          <p
            className="fw-bold"
            style={{
              fontSize: (fontMontana - 50),
            }}
          >
            Montana
          </p>
          <p
            className="fw-bold"
            style={{
              fontSize: (fontCR - 52),
            }}
          >
            Costa Rica
          </p>
        </div>
      </div>
      <div className="content-container d-flex flex-column position-relative">
        <ReactCompareImage
          aspectRatio="wider"
          sliderLineColor="#04BCCA"
          sliderLineWidth="3"
          leftImage={montanaImage}
          leftImageAlt="Montana"
          leftImageCss={{ filter: `brightness(${brightnessMT}%)` }}
          rightImage={crImage}
          rightImageAlt="Costa Rica"
          rightImageCss={{ filter: `brightness(${brightnessCR}%)` }}
          onSliderPositionChange={setPositionSlider}
          handle={<div className="handler-button"><img src={handleSlider} alt="handle-slider" /></div>}
        />
        <div className="text-locations position-absolute">
          <div className="left d-flex flex-column position-relative">
            <div className="mt-text d-flex position-relative">
              <p
                className="fw-bold position-absolute"
                style={{
                  fontSize: fontMontana,
                  left: '0%',
                  top: `${positionYMT}%`,
                  bottom: '0%',
                  right: `${positionXMT}%`,
                }}
              >
                Montana
              </p>
            </div>
          </div>
          <div className="right d-flex flex-column position-relative">
            <div className="cr-text d-flex position-relative">
              <p
                className="fw-bold position-absolute"
                style={{
                  fontSize: fontCR,
                  left: `${positionXCR}%`,
                  top: `${positionYCR}%`,
                  bottom: '0%',
                  right: '0%',
                }}
              >
                Costa Rica
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutUsLocationsSlider;
