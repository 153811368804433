import React from 'react';
import './AboutUsOurOffices.scss';

import sustainability from '../../../images/sustainability.avif';

const Sustainability = () => (
  <div className="sustainability d-flex flex-column position-relative pb-5">
    <div className="d-flex flex-column flex-md-row office-cards-container px-md-5">
      <div className="office-card d-flex flex-column justify-content-center mx-md-5">
        <div className="d-flex bz d-flex mt-1 mt-md-0">
          <div className="text-location d-flex flex-column">
            <h5 className="fw-bold mb-0 ms-2 ms-md-4">Our Commitment</h5>
            <p className="fw-bold mt-3 mt-md-4 mb-0 ms-2 ms-md-4">At Build, we align ourselves with the environmental initiatives of the Costa Rican government.</p>
            <p className="mt-3 mt-md-4 mb-0 ms-2 ms-md-4">Build is devoted to relying on renewable energy sources aiming to diminish our Ecological footprint. Our commitment extends to reducing CO2 emissions, encouraging recycling initiatives, and the adherence to sustainability guidelines in all our operations.</p>
          </div>
        </div>
      </div>
      <div className="office-card d-flex sustainability flex-column justify-content-center mt-4 mt-md-0 mx-md-5">
        <div className="d-flex map-location cr d-flex mt-1 mt-md-0 p-1">
          <div className="text-location sustainability-img d-flex flex-column">
            <img className="ms-2" src={sustainability} alt="Sustainability" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Sustainability;
